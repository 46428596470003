import type { DataObjectOptions, DataObjectMetaOptions } from 'o365.modules.DataObject.Types.ts';
import type { default as Procedure, IProcedureOptions } from 'o365.modules.Procedure.ts';
import 'o365.modules.pollyfills.ts';
import { initialize, getAppConfig, getExternalAppConfig } from 'o365.modules.configs.ts';
// import utils from 'o365.modules.utils.js';
// import translate from 'o365.modules.translate.ts';
// import appInsights from 'o365.modules.AppInsights.ts';
import GlobalProperties from 'o365.vue.plugins.GlobalProperties.ts';
import DataObject from 'o365.modules.DataObject.ts';
import { getOrCreateProcedure as _getOrCreaetProcedure } from 'o365.modules.Procedure.ts';
import { ref, createApp as VueCreateApp } from 'vue';
// await initialize();


const config = getAppConfig();

export const dataObjectStores = new Map<string, Map<string, ref<DataObject>>>();
export const procedureStores = new Map<string, Procedure>();

function _checkStoreScope(pAppId = 'site') {
    if (!dataObjectStores.has(pAppId)) {
        dataObjectStores.set(pAppId, new Map())
    }
}

export function getOrCreateProcedure<T extends object = any>(pOptions: IProcedureOptions): Procedure<T> {
    if (!procedureStores.has(pOptions.id)) {
        procedureStores.set(pOptions.id, ref(_getOrCreaetProcedure(pOptions)).value);
    }
    return procedureStores.get(pOptions.id)!;
}

export function createDataObject(pOptions: DataObjectOptions, pMetaOptions?: DataObjectMetaOptions): DataObject {
    if (!pOptions.appId) {
        pOptions.appId = 'site';
    }
    _checkStoreScope(pOptions.appId);

    dataObjectStores.get(pOptions.appId)?.set(pOptions.id, new ref(new DataObject(pOptions, pMetaOptions)));
    dataObjectStores.get(pOptions.appId)!.get(pOptions.id).value.initializeExtensions(pOptions);

    return dataObjectStores.get(pOptions.appId)!.get(pOptions.id).value;
}

export function $t(pStr : string, pAppId? : string) {{
    if (!pAppId) {{
        pAppId = 'site';
    }}

    return window.$t(pStr, pAppId);
}}

export function initDataObjectFromConfig(pId: string, pAppId = 'site'): void {
    let vConfig: Partial<typeof config> | undefined = undefined;
    if (pAppId === 'site' || config.id === pAppId) {
        vConfig = config;
    } else {
        vConfig = getExternalAppConfig(pAppId);
    }
    if (vConfig == null) {
        throw new Error(`No config found for app ${pAppId}`);
    }

    let vOptions = vConfig["dataObjectConfigs"]!.get(pId);
    if (!vOptions) {
        throw new Error(`DataObject with Id ${pId} does not exist in ${pAppId}`);
    }
    vOptions = { ...vOptions };
    if (!vOptions.appId) {
        vOptions.appId = pAppId;
    }

    vOptions['viewDefinition'] = vConfig["viewDefinitions"] != null ? vConfig["viewDefinitions"][vOptions.viewName] : null;
    vOptions['uniqueTableDefinition'] = vConfig["viewDefinitions"] != null ? vConfig["viewDefinitions"][vOptions.uniqueTable] : null;

    createDataObject(vOptions, { isFromDesigner: true});
}

export function deleteDataObject(pId: string, pAppId = 'site'): void {
    if (dataObjectStores.get(pAppId)?.has(pId)) {
        dataObjectStores.get(pAppId)!.get(pId).value.destroyDataObject();
        dataObjectStores.get(pAppId)!.delete(pId);
    }
}

/** Data object item models */
export interface DataObjectModels { };

export function getOrCreateDataObject<T extends object = any>(pOptions: DataObjectOptions): DataObject<T> {
    if (!pOptions.appId) {
        pOptions.appId = 'site';
        if (pOptions.disableLayouts !== false) {
            // Disable layouts for dynamic dataobjects from sitesetup files
            pOptions.disableLayouts = true;
        }
    }
    _checkStoreScope(pOptions.appId);

    if (!dataObjectStores.get(pOptions.appId)!.has(pOptions.id)) {
        return createDataObject(pOptions);
    }

    return dataObjectStores.get(pOptions.appId)!.get(pOptions.id).value;
}

export function getDataObjectById<T extends keyof DataObjectModels, K extends string>(pId: T | K, pAppId = 'site'): DataObject<K extends keyof DataObjectModels ? DataObjectModels[T] : any> {
    try {
        if (!dataObjectStores.get(pAppId)?.has(pId)) {
            initDataObjectFromConfig(pId, pAppId);
        }

        return dataObjectStores.get(pAppId)!.get(pId).value;
    } catch (ex) {
        if (ex?.message && config.isDebug) {
            import('o365.controls.alert.ts').then(alertModule => {
                alertModule.default(ex?.message, 'warning', {
                    autohide: true
                });
            });
        }
        console.error(ex);
    }
}

export async function createApp(options: any, o365Options: {
    includeProperties?: boolean;
    includeComponents?: boolean;
    includeDirectives?: boolean;
    customIsCustomElement?: any;
    customResolveComponents?: any;
    appId?: string;
} = {
        includeProperties: true,
        includeComponents: true,
        includeDirectives: true,
        customIsCustomElement: undefined,
        customResolveComponents: undefined,
        appId: 'site'
    }): Promise<any> {
    const includeProperties = o365Options.includeProperties ?? true;
    const includeComponets = o365Options.includeComponents ?? true;
    const includeDirectives = o365Options.includeDirectives ?? true;
    const customIsCustomElement = o365Options.customIsCustomElement;
    const customResolveComponents = o365Options.customResolveComponents;

    //  await appConfigPr;
    const app = VueCreateApp(options);

    // app.config.compilerOptions.isCustomElement = customIsCustomElement ?? ((tag) => {
    //     return tag.startsWith('o365-') || tag.startsWith('partial');
    // });

    if ((includeProperties ?? true) || includeDirectives) {
        app.use(GlobalProperties, {
            includeProperties: includeProperties,
            includeDirectives: includeDirectives,
        })
    }

    app.provide('resolveComponent', customResolveComponents ?? ((name) => {
        let component = app._component.components[name];

        if (component) {
            return component;
        }

        if (name.includes('-')) {
            const pascalName = name.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join('');

            component = app._component.components[pascalName];
        } else {
            const kebabName = name.replace(/([A-Z][a-z])/g, '-$1').toLowerCase();

            component = app._component.components[kebabName];
        }

        return component;
    }));

    if (window.top === window.self) {
        const checkNotes = () => {
            import('o365.modules.StorageHelpers.ts').then((helpers) => {
                const shouldOpenNotes = helpers.default.getItem('o365-notes-app-open', { global: true }) === 'true';
                if (shouldOpenNotes) {
                    import('o365.controls.NotesApp.ts').then((notesModule) => {
                        window.setTimeout(() => {
                            notesModule.default();
                        }, 100);
                    });
                }
            });
        }
        if (app._component.mounted) {
            const mountedFunc = app._component.mounted;
            app._component.mounted = function() {
                mountedFunc.call(this);
                checkNotes();
            };
        } else {
            app._component.mounted = checkNotes;
        }
    } else {
        // App created inside an iframe. Notify parent window. 
        window.parent.postMessage({ type: 'o365-system', action: 'app-created' });
        const parentMessageChannel: { connected: boolean, port: MessagePort} = { connected: false, port: null };
        app.provide('o365-parent-message-channel', parentMessageChannel)
        addEventListener('message', (pEvent) => {
            const data = pEvent.data;
            if (data == null || typeof data !== 'object' || data.type !== 'o365-system' || data.action !== 'establish-channel') {
                return;
            }
            if (window.parent != pEvent.source || pEvent.ports[0] == null) { return; }
            parentMessageChannel.port = pEvent.ports[0];
            parentMessageChannel.connected = true;
        });
    }

    return app;
}

export function getDataObjectConfigById(dataObjectId: string) {
    return config?.dataObjectConfigs?.get(dataObjectId);
}
